import React, { useState, useEffect } from "react";
import AdminHeader from "./layouts/AdminHeader";
import AdminSideBar from "./layouts/AdminSideBar";
import AdminFooter from "./layouts/AdminFooter";
import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE_URL;

export default function Billing() {
  const [medicines, setMedicines] = useState([]);
  const [filteredMedicines, setFilteredMedicines] = useState([]);
  const [billingList, setBillingList] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [quantities, setQuantities] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchMedicines = async () => {
      try {
        const response = await axios.get(`${baseURL}/drugs`);
        setMedicines(response.data);
        setFilteredMedicines(response.data);
      } catch (error) {
        console.error("Error fetching medicines:", error);
        setErrorMessage(
          "Failed to fetch medicines. Please try again later."
        );
      }
    };

    fetchMedicines();
  }, []);

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
    const filtered = medicines.filter(
      (medicine) =>
        medicine.DrugName.toLowerCase().includes(event.target.value.toLowerCase()) ||
        medicine.DrugID.toString().toLowerCase().includes(event.target.value.toLowerCase()) ||
        medicine.Manufacturer.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setFilteredMedicines(filtered);
  };

  const handleAddToBillingList = (id) => {
    const selectedMedicine = medicines.find(
      (medicine) => medicine.DrugID === id
    );
    if (selectedMedicine) {
      const updatedMedicine = {
        ...selectedMedicine,
        quantity: quantities[id] || 1,
      };
      setBillingList([...billingList, updatedMedicine]);
    }
  };

  const handleCalculateTotal = () => {
    const total = billingList.reduce(
      (acc, curr) =>
        acc + parseFloat(curr.SellingPrice) * curr.quantity,
      0
    );
    setTotalAmount(total);
  };

  const handleQuantityChange = (id, quantity) => {
    setQuantities({ ...quantities, [id]: quantity });
  };

  const handleBilling = async () => {
    try {
      for (const medicine of billingList) {
        const inventoryResponse = await axios.get(`${baseURL}/inventory?DrugID=${medicine.DrugID}`);
        const currentStockLevel = inventoryResponse.data[0].StockLevel;
  
        if (quantities[medicine.DrugID] > currentStockLevel) {
          throw new Error(`Insufficient stock for ${medicine.DrugName}. Current stock: ${currentStockLevel}`);
        }
  
        const updatedStockLevel = currentStockLevel - quantities[medicine.DrugID];
  
        await axios.put(`${baseURL}/inventory/${medicine.DrugID}`, {
          StockLevel: updatedStockLevel,
          ReorderPoint: medicine.ReorderPoint
        });

        const transactionData = {
          DrugID: medicine.DrugID,
          TransactionType: "Sale",
          QuantityIn: 0,
          CostOfProduction: parseFloat((medicine.SellingPrice)*quantities[medicine.DrugID]),
          QuantityOut: quantities[medicine.DrugID],
          //Profit: parseFloat((medicine.SellingPrice)*quantities[medicine.DrugID]) -parseFloat((medicine.UnitPrice)*(quantities[medicine.DrugID]))
        };

        await axios.post(`${baseURL}/transactions`, transactionData);
      }

      // Open billing summary in a popup window
      handlePrintBill();
      
      setBillingList([]);
      setQuantities({});
      setTotalAmount(0);
      setErrorMessage("");
    } catch (error) {
      console.error("Error billing:", error);
      setErrorMessage(error.message);
    }
  };

  const handlePrintBill = () => {
    const pharmacyInfo = {
      name: "MBULE PHARMACY",
      tin: "TIN NO: 132-190-267",
      email: "Mbulepharmacy@gmail.com",
      mobile: "0785299258 / 0657284300",
      location: "Mbekenyera (Ruangwa)"
    };
  
    const ownerInfo = `
      <div class="pharmacy-info">
        <h1 style="color: #4CAF50;">${pharmacyInfo.name}</h1>
        <p>${pharmacyInfo.tin}</p>
        <p>Email: ${pharmacyInfo.email}</p>
        <p>Mobile No: ${pharmacyInfo.mobile}</p>
        <p>Location: ${pharmacyInfo.location}</p>
      </div>
    `;
  
    const billContent = `
      <html>
        <head>
          <title>Invoice</title>
          <style>
            /* Add your custom styles for the bill here */
            body {
              font-family: Arial, sans-serif;
              padding: 20px;
              background-color: #f7f7f7;
            }
            .invoice-container {
              max-width: 800px;
              margin: 0 auto;
              padding: 20px;
              background-color: #fff;
              border-radius: 10px;
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            }
            .header {
              text-align: center;
              margin-bottom: 20px;
            }
            .header h1 {
              color: #333;
              margin-bottom: 5px;
            }
            .header p {
              color: #666;
              margin: 0;
            }
            .billing-date {
              margin-bottom: 20px;
            }
            .invoice-table {
              width: 100%;
              border-collapse: collapse;
            }
            .invoice-table th, .invoice-table td {
              border: 1px solid #ddd;
              padding: 8px;
            }
            .invoice-table th {
              background-color: #f2f2f2;
              text-align: left;
            }
            .invoice-table td {
              text-align: left;
            }
            .total {
              margin-top: 20px;
              text-align: right;
              font-weight: bold;
            }
            .logo img {
              max-width: 150px;
              height: auto;
              margin-bottom: 20px;
              display: block;
              margin: 0 auto;
              background-color: transparent;
            }
          </style>
        </head>
        <body>
          <div class="invoice-container">
            <div class="logo">
              <img src="logo.jpg" alt="Logo">
            </div>
            <div class="header">
              <h1 style="color: #4CAF50;">Invoice</h1>
              ${ownerInfo}
            </div>
            <div class="billing-date">
              <p><strong>Billing Date:</strong> ${new Date().toLocaleDateString()}</p>
            </div>
            <table class="invoice-table">
              <thead>
                <tr>
                  <th>Medicine</th>
                  <th>Qty</th>
                  <th>Price</th>
                  <th>Amount</th>
                  <th>Disc%</th>
                  <th>Manufacturer</th>
                  <th>Expiry</th>
                </tr>
              </thead>
              <tbody>
                ${billingList.map((medicine) => `
                  <tr>
                    <td>${medicine.DrugName}</td>
                    <td>${medicine.quantity}</td>
                    <td>TZS${medicine.SellingPrice}</td>
                    <td>TZS${(parseFloat(medicine.SellingPrice) * parseFloat(medicine.quantity)).toFixed(2)}</td>
                    <td>0</td>
                    <td>${medicine.Manufacturer}</td>
                    <td>${new Date(medicine.ExpiryDate).toLocaleDateString()}</td>
                  </tr>
                `).join("")}
              </tbody>
            </table>
            <p class="total">Total Amount: TZS${parseFloat(totalAmount).toFixed(2)}</p>
          </div>
        </body>
      </html>
    `;
  
    const billWindow = window.open("", "_blank", "resizable=yes,width=700,height=600");
    billWindow.document.write(billContent);
    billWindow.document.close();
  
    billWindow.document.body.innerHTML += `
      <div style="text-align: center; margin-top: 20px;">
        <button onclick="window.print()">Print Invoice</button>
        <button onclick="window.close()">Close</button>
      </div>
    `;
  };

  return (
    <>
      <AdminHeader />
      <AdminSideBar />
      <div className="main-panel">
        <div className="content">
          <div className="container-fluid">
            <h4 className="page-title">Billing</h4>
            <div className="row">
              <div className="col-md-12">
                <div className="card card-tasks">
                  <div className="card-header ">
                    <h4 className="card-title">Medicine Sale</h4>
                  </div>
                  <div className="card-body">
                    {errorMessage && (
                      <div className="alert alert-danger">{errorMessage}</div>
                    )}
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search medicine"
                        value={searchTerm}
                        onChange={handleSearchInputChange}
                      />
                      <button
                        type="button"
                        className="btn btn-primary mt-2"
                        onClick={handleSearchInputChange}
                      >
                        Search
                      </button>
                    </div>
                    <div className="table-full-width px-5 py-4 table-striped">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Medicine Name</th>
                            <th>Dosage</th>
                            <th>Manufacturer</th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredMedicines.map((medicine, index) => (
                            <tr key={medicine.DrugID}>
                              <td>{medicine.DrugName}</td>
                              <td>{medicine.Dosage}</td>
                              <td>{medicine.Manufacturer}</td>
                              <td>TZS{medicine.SellingPrice}</td>
                              <td>
                                <input
                                  type="number"
                                  min="1"
                                  value={quantities[medicine.DrugID] || ""}
                                  onChange={(e) =>
                                    handleQuantityChange(
                                      medicine.DrugID,
                                      parseInt(e.target.value)
                                    )
                                  }
                                  onKeyUp={(e) =>
                                    handleQuantityChange(
                                      medicine.DrugID,
                                      parseInt(e.target.value)
                                    )
                                  }
                                  className="form-control"
                                />
                              </td>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-success btn-sm"
                                  onClick={() =>
                                    handleAddToBillingList(medicine.DrugID)
                                  }
                                >
                                  Add
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="mt-3">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleCalculateTotal}
                      >
                        Calculate Total
                      </button>
                      <button
                        type="button"
                        className="btn btn-success ml-3"
                        onClick={handleBilling}
                      >
                        Bill
                      </button>
                    </div>
                    <div className="mt-3">
                      <h5>Total Amount: TZS{parseFloat(totalAmount).toFixed(2)}</h5>
                      <ul>
                        {billingList.map((medicine, index) => (
                          <li key={index}>
                            {medicine.DrugName} - TZS
                            {(
                              parseFloat(medicine.SellingPrice) *
                              parseFloat(medicine.quantity)
                            ).toFixed(2)}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AdminFooter />
      </div>
    </>
  );
}
