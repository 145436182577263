import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import AdminHeader from "./layouts/AdminHeader";
import AdminSideBar from "./layouts/AdminSideBar";
import AdminFooter from "./layouts/AdminFooter";
import axios from "axios"; // Import Axios library

const baseURL = process.env.REACT_APP_API_BASE_URL;

export default function AddMedicine() {
  const navigate = useNavigate();
  const [medicine, setMedicine] = useState({
    StockLevel: "",
    ReorderPoint: "",
    DrugName: "" // New state for drug name
  });
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [drugs, setDrugs] = useState([]);

  useEffect(() => {
    const fetchDrugs = async () => {
      try {
        const response = await axios.get(`${baseURL}/drugs`);
        setDrugs(response.data);
      } catch (error) {
        console.error("Error fetching drugs:", error);
        setErrorMsg("Failed to fetch drugs. Please try again later.");
      }
    };
    fetchDrugs(); // Call fetchDrugs function
  }, []);

  const handleAddMedicine = () => {
    if (medicine.StockLevel && medicine.ReorderPoint && medicine.DrugName) {
      setErrorMsg("");

      const selectedDrug = drugs.find((drug) => drug.DrugName === medicine.DrugName);

      if (selectedDrug) {
        const medicineData = {
          DrugID: selectedDrug.DrugID,
          StockLevel: medicine.StockLevel,
          ReorderPoint: medicine.ReorderPoint
        };

        axios
          .post(`${baseURL}/inventory`, medicineData)
          .then((response) => {
            setSuccessMsg("Medicine added successfully!");
            setTimeout(() => {
              setSuccessMsg("");
              navigate("/inventory");
            }, 1000);
          })
          .catch((error) => {
            console.error("Error adding medicine:", error);
            setErrorMsg("Error adding medicine. Please try again later.");
          });
      } else {
        setErrorMsg("Drug not found in the database. Please enter a valid drug name.");
      }
    } else {
      setErrorMsg("Please fill out all the required fields!");
    }
  };

  return (
    <>
      <AdminHeader />
      <AdminSideBar />
      <div className="main-panel">
        <div className="content">
          <div className="container-fluid">
            <h4 className="page-title">Create Medicine</h4>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title">
                      New Medicine Details
                      <Link to="/inventory" className="btn btn-danger btn-sm float-right">
                        Go BACK
                      </Link>{" "}
                    </div>
                  </div>
                  <div className="card-body px-4">
                    <div className="form-group">
                      <label htmlFor="DrugName">Drug Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={medicine.DrugName}
                        id="DrugName"
                        onChange={(event) =>
                          setMedicine((prev) => ({ ...prev, DrugName: event.target.value }))
                        }
                        placeholder="Enter Drug Name"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="StockLevel">Stock Level</label>
                      <input
                        type="text"
                        className="form-control"
                        value={medicine.StockLevel}
                        id="StockLevel"
                        onChange={(event) =>
                          setMedicine((prev) => ({ ...prev, StockLevel: event.target.value }))
                        }
                        placeholder="Total number (quantity/boxes/bottles/number of drugs)"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="ReorderPoint">Reorder Point</label>
                      <input
                        type="text"
                        className="form-control"
                        value={medicine.ReorderPoint}
                        id="ReorderPoint"
                        onChange={(event) =>
                          setMedicine((prev) => ({ ...prev, ReorderPoint: event.target.value }))
                        }
                        placeholder="Enter Reorder Point"
                      />
                    </div>
                  </div>

                  <div className="form-group px-4 mb-3">
                    <div className="text-center text-danger">{errorMsg}</div>
                    <div className="text-center text-success">{successMsg}</div>
                    <button className="btn btn-primary mx-3" onClick={handleAddMedicine}>
                      Add Medicine
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AdminFooter />
      </div>
    </>
  );
}
